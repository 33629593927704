import { createContext, useContext } from 'react';
import { Activity } from './types';
import { useQueryActivities } from './useQueryActivities';

type ActivitiesContext = Activity[];

export const ActivitiesContext = createContext<ActivitiesContext | null>(null);
export const useActivitiesContext = () => {
  const context = useContext<ActivitiesContext | null>(ActivitiesContext);

  if (!context) {
    throw new Error('useActivitiesContext must be used within an ActivitiesProvider');
  }

  return context;
};

export const ActivitiesProvider = ({ children }: { children: React.ReactNode }) => {
  const { result } = useQueryActivities();
  const value = result.map((activity) => ({
    ...activity,
    isViewedByMultipleUsers: activity.who ? activity.who?.length > 1 : false,
  }));

  return <ActivitiesContext.Provider value={value}>{children}</ActivitiesContext.Provider>;
};
