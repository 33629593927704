import { Box, Drawer } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { ActivitiesProvider } from './ActivitiesProvider';
import { InsightsExpandedContent } from './InsightsExpandedContent';
import { InsightsShrunkContent } from './InsightsShrunkContent';
import { InsightsPanelShrunkHeader } from './headers/InsightsPanelShrunkHeader';
import { InsightsPanelExpandedHeader } from './headers/InsightsPanelExpandedHeader';

const LEFT_SIDEBAR_WIDTH = 56;
const LEFT_SIDEBAR_BREAKPOINT = 1000;

interface InsightsPanelProps {
  isOpen: boolean;
  onClose: ({ isExpanded }: { isExpanded: boolean }) => void;
  documentId: string;
}

export function InsightsPanel({ isOpen, onClose, documentId }: InsightsPanelProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const drawerRef = useRef<HTMLDivElement>(null);

  const onCloseDrawer = () => {
    setIsExpanded(false);
    onClose({ isExpanded });
  };

  useEffect(() => {
    // add smooth resize animation to the drawer
    if (drawerRef.current && !drawerRef.current.style.transition.includes('width 0.5s')) {
      drawerRef.current.style.transition = drawerRef.current.style.transition + ', width 0.5s';
    }
  }, [drawerRef.current]);

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onCloseDrawer}
      data-testid="insights-drawer"
      PaperProps={{
        'data-testid': 'insights-panel-content',
        ref: drawerRef,
        sx: {
          overflowX: 'hidden',
          width: isExpanded ? { xs: '100vw' } : { xs: '60vw', xl: '50vw' },
          ...(isExpanded && {
            [`@media (min-width:${LEFT_SIDEBAR_BREAKPOINT}px)`]: {
              width: `calc(100vw - ${LEFT_SIDEBAR_WIDTH}px)`,
            },
          }),
        },
      }}
    >
      <Box display="flex" flexDirection="column">
        <Box data-testid="insights-header" display="flex" alignItems="center" padding="1rem 1.5rem" bgcolor="background.paper">
          {isExpanded ? (
            <InsightsPanelExpandedHeader
              documentId={documentId}
              onClose={onCloseDrawer}
              onToggleExpand={() => setIsExpanded(!isExpanded)}
              isExpanded={isExpanded}
            />
          ) : (
            <InsightsPanelShrunkHeader
              documentId={documentId}
              onClose={onCloseDrawer}
              onToggleExpand={() => setIsExpanded(!isExpanded)}
              isExpanded={isExpanded}
            />
          )}
        </Box>
        <ActivitiesProvider>{isExpanded ? <InsightsExpandedContent /> : <InsightsShrunkContent />}</ActivitiesProvider>
      </Box>
    </Drawer>
  );
}
