import { Typography } from '@mui/material';
import { useFormatDateTime } from '../utils/useFormatDateTime';
import { useActivitiesContext } from './ActivitiesProvider';
import { ActivityAvatar } from './activity-avatar';
import { Milestone, MilestoneContent, MilestoneDate, MilestoneInfo, Timeline } from './timeline';

export function Activities() {
  const activities = useActivitiesContext();
  const { formatDocumentDateTime } = useFormatDateTime();

  return (
    <Timeline data-testid="insights-activities">
      {activities.map((activity, index) => (
        <Milestone
          key={activity.id}
          data-testid={`insights-activity-${activity.id}`}
          isTitleAlignedWithIcons={!activity.isViewedByMultipleUsers}
          isLast={index === activities.length - 1}
        >
          <MilestoneDate data-testid={`insights-activity-date-${activity.id}`}>
            <Typography variant="body2" color="text.secondary" fontWeight="bold">
              {formatDocumentDateTime(activity.date.toISOString())}
            </Typography>
          </MilestoneDate>
          <MilestoneContent>
            <ActivityAvatar activity={activity} />
            <MilestoneInfo>
              <Typography variant="body1" color="text.primary" fontWeight="bold" sx={{ overflowWrap: 'anywhere' }}>
                {activity.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {activity.details}
              </Typography>
            </MilestoneInfo>
          </MilestoneContent>
        </Milestone>
      ))}
    </Timeline>
  );
}
