import { Avatar } from '@mui/material';
import IcoAutomation from 'components/icon/icons/IcoAutomation';
import IcoDocuments from 'components/icon/icons/IcoDocuments';
import IcoFolder2 from 'components/icon/icons/IcoFolder2';
import IcoLost from 'components/icon/icons/IcoLost';
import IcoPencil from 'components/icon/icons/IcoPencil';
import IcoPointer from 'components/icon/icons/IcoPointer';
import IcoTrash from 'components/icon/icons/IcoTrash';
import IcoWon from 'components/icon/icons/IcoWon';
import { Activity, ActivityType } from '../types';
import { OverlappableAvatars } from './OverlappableAvatars';

const iconMap: { [key in ActivityType]: JSX.Element | null } = {
  document_section_view: <IcoPointer />,
  document_section_edit: <IcoPencil />,
  document_link_click: <IcoPointer />,
  document_moved: <IcoFolder2 />,
  document_trashed: <IcoTrash />,
  update_status_lost: <IcoLost />,
  update_status_won: <IcoWon />,
  template_created: <IcoDocuments />,
  automated_email_sent: <IcoAutomation />,
};

export function ActivityAvatar({ activity }: { activity: Activity }) {
  if (activity.isViewedByMultipleUsers) {
    return <OverlappableAvatars users={activity.who || []} />;
  }

  if (iconMap[activity.type]) {
    return <Avatar>{iconMap[activity.type]}</Avatar>;
  }

  return null;
}
