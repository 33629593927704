import { GridCellParams, MuiEvent } from '@mui/x-data-grid-pro';
import { KeyboardEvent } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'components/editor/grid/reduxStore/Store';
import { useBlockContentChangedHandler } from 'components/editor/hooks/UseBlockContentChangedHandler';
import { useTableManipulation } from './useTableManipulation';
import { cellFocusOutEventKeys } from './variables';
import { isRowBelongsToTotal } from '../../../../../muiTheme/dataGridUtils';

export const useTableCellManipulation = (blockId: string, sectionId: string, toggledTableSettingsPanel: any) => {
  const blockContentChangedHandler = useBlockContentChangedHandler();
  const { getUpdatedRowsTableDataFromCellEdit } = useTableManipulation();
  const { activeTableSettingsPanel } = useSelector((state: RootState) => state.blockStyleSettings);

  const onCellEdited = async (cellParams: GridCellParams, event: MuiEvent<KeyboardEvent>) => {
    if (cellFocusOutEventKeys.includes(event.key)) return;
    const cellTarget = event.target as HTMLInputElement;
    const updatedTableData = getUpdatedRowsTableDataFromCellEdit(cellParams, cellTarget.value, blockId, sectionId);
    if (!updatedTableData) return;

    await blockContentChangedHandler(blockId, sectionId, updatedTableData);
  };

  function getCellClassName(params: GridCellParams) {
    const { row, colDef } = params;
    const isColumnSelected = activeTableSettingsPanel && toggledTableSettingsPanel?.tableApi.selectedModel.field === params.field;
    const isCellEditingAllowed = row.cellConfig ? row.cellConfig[colDef.field] ?? true : true;
    const isCellHidden = row.cellConfig && row.cellConfig[colDef.field]?.hideCell;

    const classNames: string[] = [];
    if (isColumnSelected) {
      classNames.push('py-selected-column-cell');
    }

    if (isRowBelongsToTotal(row)) {
      classNames.push('py-total-cell');
    }

    if (!isCellEditingAllowed) {
      classNames.push('py-number-input-no-arrow');
    }
    if (isCellHidden) {
      classNames.push('py-hide-cell');
    }
    return classNames.join(' ');
  }

  const handleIsCellEditable = (params: GridCellParams): boolean => {
    const { row, colDef } = params;
    return row.cellConfig && row.cellConfig[colDef.field] !== undefined ? row.cellConfig[colDef.field] : true;
  };

  return {
    onCellEdited,
    getCellClassName,
    handleIsCellEditable,
  };
};
