import { useQuery } from 'react-query';
import { ActivityRaw } from './types';

export function useQueryActivities() {
  const { data } = useQuery(['getActivities'], () => mockActivityList);
  const result = !data ? [] : data;
  return {
    result,
  };
}

const mockActivityList: ActivityRaw[] = [
  {
    id: 1,
    date: new Date('2024-01-01'),
    type: 'document_section_view',
    title: '6 new unique views on “Products” section.',
    who: ['John Doe', 'Parker Doe', 'Nancy Jane Watson', 'Bob Marley', 'Hannah Montana', 'Heidi Klum'],
  },
  {
    id: 2,
    date: new Date('2024-01-01'),
    type: 'document_section_edit',
    title: 'Grace edited “Pricing” section.',
    details: 'Changes made to Pricing Table and Products Section',
  },
  {
    id: 3,
    date: new Date('2024-01-01'),
    type: 'document_link_click',
    title: 'email@idenitifiedviewer.com clicked “Terms and Conditions” link.',
  },
  {
    id: 4,
    date: new Date('2024-01-01'),
    type: 'document_section_view',
    title: 'Anonymous viewed “Statement of Work” section.',
    details: 'Viewed for 8 minutes.',
  },
  {
    id: 5,
    date: new Date('2024-02-01'),
    type: 'document_section_view',
    title: 'Three interesting users viewed “Insights” section.',
    details: 'Viewed for 2 minutes.',
    who: ['An interesting user', 'Another interesting user', 'A third interesting user'],
  },
  {
    id: 6,
    date: new Date('2024-02-02'),
    type: 'document_moved',
    title: 'Document “Project Plan” moved to “Completed Projects” folder.',
  },
  {
    id: 7,
    date: new Date('2024-02-03'),
    type: 'document_trashed',
    title: 'Document “Old Contract” moved to trash.',
  },
  {
    id: 8,
    date: new Date('2024-02-04'),
    type: 'update_status_lost',
    title: 'Status updated to “Lost” for deal “Big Client”.',
  },
  {
    id: 9,
    date: new Date('2024-02-05'),
    type: 'update_status_won',
    title: 'Status updated to “Won” for deal “New Client”.',
  },
  {
    id: 10,
    date: new Date('2024-02-06'),
    type: 'template_created',
    title: 'New template “Sales Pitch” created.',
  },
  {
    id: 11,
    date: new Date('2024-02-07'),
    type: 'automated_email_sent',
    title: 'Automated email sent to “prospect@company.com”.',
  },
  {
    id: 12,
    date: new Date('2024-02-03'),
    type: 'document_trashed',
    title: 'Document “Old Contract” moved to trash.',
    details:
      'Trashed by Parker Doe. This document was considered obsolete and no longer relevant to the current project. It was originally created in 2015 and had not been updated since 2018. After a thorough review by the legal team, it was decided that the document could be safely discarded. Parker Doe, the project manager, took the necessary steps to ensure that all important information was archived before moving the document to the trash. This action was part of a larger effort to clean up and organize the company’s digital files, ensuring that only current and relevant documents are kept in the active project folders.',
  },
  {
    id: 13,
    date: new Date('2024-02-08'),
    type: 'automated_email_sent',
    title: 'Sending scheduled with “Potential Partner”.',
    details:
      'Scheduled by Jane Smith. The meeting is set to discuss potential collaboration opportunities and will take place on February 15, 2024, at 10:00 AM. Jane Smith, the business development manager, will lead the discussion, focusing on mutual benefits and strategic alignment between the two companies. The meeting aims to explore synergies and outline a roadmap for future partnership.',
    who: ['Jane Smith', 'Potential Partner Representative'],
  },
];
